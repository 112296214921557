import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const ExpiredContext = createContext<any>({} as any);

export const ExpiredContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  type CategoryType = "" | "LONGFILL" | "PREMIX" | "LIQUID";

  interface IProductsArr {
    name: string;
    piece: number;
    month?: number;
    year?: number;
    category: CategoryType;
    user?: string;
    id: string;
    EAN?: number;
  }

  const [cookies] = useCookies(["auth-token"]);

  const [isAuth, setIsAuth] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [category, setCategory] = useState<CategoryType>("");
  const [savedProductsArr, setSavedProductsArr] = useState<IProductsArr | null>(
    null
  );
  const [savedSoldArr, setSavedSoldArr] = useState<IProductsArr | null>(null);

  useEffect(() => {
    setIsAuth(cookies["auth-token"] ? true : false);
  }, []);

  return (
    <ExpiredContext.Provider
      value={{
        isAuth,
        setIsAuth,
        search,
        setSearch,
        category,
        setCategory,
        savedProductsArr,
        setSavedProductsArr,
        savedSoldArr,
        setSavedSoldArr,
      }}
    >
      {children}
    </ExpiredContext.Provider>
  );
};

export default ExpiredContext;
