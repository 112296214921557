import React, { SetStateAction, useContext, useEffect, useState } from "react";
import "../style/PopUpEditData.scss";

import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../config";
import toast from "react-hot-toast";
import ExpiredContext from "../context/Context";

interface IPopUpEditData {
  setPopUpEditData: React.Dispatch<SetStateAction<boolean>>;
  title: string;
  docId: string;
  currentName: string;
  currentPiece: number;
  currentMonth?: number;
  currentYear?: number;
}

const PopUpEditData: React.FC<IPopUpEditData> = ({
  setPopUpEditData,
  title,
  docId,
  currentName,
  currentPiece,
  currentMonth,
  currentYear,
}) => {
  const context = useContext(ExpiredContext);
  const { setSavedProductsArr } = context;

  const currentYearDate = new Date().getFullYear();

  const [name, setName] = useState<string>(currentName);
  const [piece, setPiece] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>(undefined);
  const [year, setYear] = useState<number | undefined>(undefined);

  const handleEditData = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((month && !year) || (!month && year))
      toast.error("nie zmieniono daty bo podana została tylko jedna wartość");
    if (name || piece || (month && year)) {
      const sellCollectionRef = doc(db, "product", docId);

      const updatedData: {
        name?: string;
        piece?: number;
        month?: number;
        year?: number;
      } = {};

      if (name) updatedData.name = name;
      if (piece) updatedData.piece = piece;
      if (month && year) {
        updatedData.month = month;
        updatedData.year = year;
      }

      try {
        await updateDoc(sellCollectionRef, updatedData);
        toast.success("Udało się zaaktualizować dane");
        setPopUpEditData(false);
        setSavedProductsArr(null);
      } catch {
        toast.error("Nie udało się zaaktualizować danych :(");
      }
    } else toast.error("wypełnij dane");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setPopUpEditData(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown as any);
    return () => {
      window.removeEventListener("keydown", handleKeyDown as any);
    };
  }, []);

  return (
    <>
      <form onSubmit={handleEditData} className="PopUpEditData">
        <div className="topBar">
          <span>{title}</span>
        </div>
        <label>
          <span>Nazwa</span>
          <input
            type="text"
            placeholder="Nazwa"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          <span>Sztuk</span>
          <input
            type="number"
            placeholder="Sztuk"
            value={piece || ""}
            onChange={(e) => setPiece(parseInt(e.target.value))}
          />
        </label>
        <div className="date">
          <label>
            <span>Miesiąc</span>
            <input
              min={1}
              max={12}
              type="number"
              placeholder="Miesiąc"
              value={month || ""}
              onChange={(e) => setMonth(parseInt(e.target.value))}
            />
          </label>
          <label>
            <span>Rok</span>
            <input
              min={2018}
              max={currentYearDate}
              type="number"
              placeholder="Rok"
              value={year || ""}
              onChange={(e) => setYear(parseInt(e.target.value))}
            />
          </label>
        </div>

        <button type="submit">Zmień dane</button>
      </form>
      <div className="Background" onClick={() => setPopUpEditData(false)} />
    </>
  );
};

export default PopUpEditData;
