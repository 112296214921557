import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'


//expired3
// const firebaseConfig = {
//   apiKey: "AIzaSyDn4iNcpfC5ICmLgWqIy-6uFVF5RJ_Ht30",
//   authDomain: "expired3-daa08.firebaseapp.com",
//   projectId: "expired3-daa08",
//   storageBucket: "expired3-daa08.appspot.com",
//   messagingSenderId: "732862496382",
//   appId: "1:732862496382:web:c62ae75664ee3c23ec222f"
// };


//expired2
const firebaseConfig = {
  apiKey: "AIzaSyBhRX9hmMaBxJIvL7WWyFOVy39CEZNt-Ps",
  authDomain: "expired2-48df5.firebaseapp.com",
  projectId: "expired2-48df5",
  storageBucket: "expired2-48df5.appspot.com",
  messagingSenderId: "604308616140",
  appId: "1:604308616140:web:29efc639c2c3c340f36369"
};



//ecpired1
// const firebaseConfig = {
//   apiKey: "AIzaSyATvywaHEvhRdh8fV2i9SjoHm7BFFWC6qg",
//   authDomain: "expired-544da.firebaseapp.com",
//   projectId: "expired-544da",
//   storageBucket: "expired-544da.appspot.com",
//   messagingSenderId: "422857966839",
//   appId: "1:422857966839:web:f74dd75502c3f52d367ff2"
// };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)