import React, { useContext, useState } from "react";
import "../style/Product.scss";
import PopUpSell from "./PopUpSell";
import xIcon from "../img/x.svg";
import nikeIcon from "../img/nike.svg";
import editIcon from "../img/edit.svg";

import PopUpConfirm from "./PopUpConfirm";
import PopUpEditData from "./PopUpEditData";

import { db } from "../config";
import { doc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import ExpiredContext from "../context/Context";

interface ProductProps {
  id: string;
  name: string;
  pieces: number;
  month?: number;
  year?: number;
  receiptIssued?: boolean;
  price?: number;
  sold?: boolean;
}

const Product: React.FC<ProductProps> = ({
  name,
  pieces,
  month,
  year,
  id,
  receiptIssued,
  price,
  sold,
}) => {
  const context = useContext(ExpiredContext);
  const { setSavedSoldArr } = context;

  const [popUpSell, setPopUpSell] = useState<boolean>(false);
  const [popUpConfirm, setPopUpConfirm] = useState<boolean>(false);
  const [popUpEditData, setPopUpEditData] = useState<boolean>(false);

  const handleChangeReceipt = async () => {
    const productRef = doc(db, "sold", id);
    try {
      await updateDoc(productRef, {
        receiptIssued: true,
      });
      toast.success("Paragon został zmieniony");
      setPopUpConfirm(false);
      setSavedSoldArr(null);
    } catch {
      toast.error("Błąd aktualizacji paragonu");
    }
  };

  return (
    <div className="Product">
      <div className="name">{name.toUpperCase()}</div>
      <div className="right">
        {month && year && (
          <div className="date">{`${
            month < 10 ? "0" + month : month
          }/${year}`}</div>
        )}

        {sold && !receiptIssued && <div className="price">{price}zł</div>}

        {sold && (
          <div
            onClick={!receiptIssued ? () => setPopUpConfirm(true) : undefined}
            className={receiptIssued ? "receipt true" : "receipt false"}
          >
            {receiptIssued ? (
              <img src={nikeIcon} alt="icon" />
            ) : (
              <img src={xIcon} alt="icon" />
            )}
          </div>
        )}

        <div className="pieces">{pieces}</div>
        {!sold && (
          <button className="edit" onClick={() => setPopUpEditData(true)}>
            <img src={editIcon} alt="icon" />
          </button>
        )}
        <button onClick={() => setPopUpSell(true)}>Sprzedaj</button>
      </div>
      {popUpSell && (
        <PopUpSell
          name={name}
          pieces={pieces}
          setPopUp={setPopUpSell}
          id={id}
        />
      )}
      {popUpConfirm && (
        <PopUpConfirm
          setPopUpConfirm={setPopUpConfirm}
          onConfirm={handleChangeReceipt}
          title={
            "Czy jesteś pewien, że chcesz oznaczyć ten paragon jako wystawiony?"
          }
        />
      )}
      {popUpEditData && (
        <PopUpEditData
          setPopUpEditData={setPopUpEditData}
          title="Zmiana danych"
          docId={id}
          currentName={name}
          currentPiece={pieces}
          currentMonth={month}
          currentYear={year}
        />
      )}
    </div>
  );
};

export default Product;
