import React, {
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ExpiredContext from "../context/Context";
import "../style/Nav.scss";
import logo from "../img/logo.svg";
import logoutIcon from "../img/logout.svg";
import cartIcon from "../img/cart.svg";
import plusIcon from "../img/plus.svg";
import soldIcon from "../img/sold.svg";

import { auth } from "../config";
import { signOut } from "firebase/auth";

import { useCookies } from "react-cookie";
import toast from "react-hot-toast";

interface NavProps {
  toggle: "SOLD" | "ADD" | "SELL";
  setToggle: React.Dispatch<SetStateAction<"SOLD" | "ADD" | "SELL">>;
}

const Nav: React.FC<NavProps> = ({ toggle, setToggle }) => {
  type CategoryType = "" | "LONGFILL" | "PREMIX" | "LIQUID";

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [, , removeCookie] = useCookies(["auth-token"]);

  const context = useContext(ExpiredContext);
  const {
    search,
    setSearch,
    category,
    setCategory,
    setIsAuth,
    setSavedSoldArr,
    setSavedProductsArr,
  } = context;

  const [displayName, setDisplayName] = useState<string | null>("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setDisplayName(user.displayName);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    await signOut(auth);
    removeCookie("auth-token");
    setIsAuth(false);
    toast.success("Logged out");
    setSavedSoldArr(null);
    setSavedProductsArr(null);
  };

  const handleToggleButton = (left: boolean) => {
    setSearch("");
    if (left) {
      if (toggle == "SELL") setToggle("SOLD");
      else setToggle("SELL");
    } else {
      setToggle("ADD");
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "/") {
      e.preventDefault();
      searchInputRef.current?.focus();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown as any);
    return () => {
      window.removeEventListener("keydown", handleKeyDown as any);
    };
  }, []);

  return (
    <div className="Nav">
      <div className="left">
        <div className="logoutContainer" onClick={handleSignOut}>
          <img src={logoutIcon} alt="logout" />
        </div>
        <span className="title">{displayName}</span>
      </div>
      <div className="searchContainer">
        <input
          ref={searchInputRef}
          placeholder="szukaj..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <select
          id="categorySelect"
          value={category}
          onChange={(e) => setCategory(e.target.value as CategoryType)}
        >
          <option value="">Wybierz kategorię</option>
          <option value="LONGFILL">LONGFILL</option>
          <option value="PREMIX">PREMIX</option>
          <option value="LIQUID">LIQUID</option>
        </select>
      </div>

      <div className="right">
        <button onClick={() => handleToggleButton(true)}>
          {toggle == "SELL" && <img src={soldIcon} alt="icon" />}
          {toggle == "SOLD" && <img src={cartIcon} alt="icon" />}
          {toggle == "ADD" && <img src={cartIcon} alt="icon" />}
        </button>
        <button onClick={() => handleToggleButton(false)}>
          {/* {toggle ? "+" : "sell"} */}
          <img src={plusIcon} alt="icon" />
        </button>
      </div>
    </div>
  );
};

export default Nav;
