import React from "react";
import ReactDOM from "react-dom/client";
import "./style/index.css";
import App from "./components/App";
import { ExpiredContextProvider } from "./context/Context";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ExpiredContextProvider>
    <React.StrictMode>
      <App />
      <Toaster />
    </React.StrictMode>
  </ExpiredContextProvider>
);
