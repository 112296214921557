import React, { SetStateAction, useContext, useEffect, useState } from "react";
import "../style/PopUpConfirm.scss";

interface IProductData {
  id: number;
  name: string;
  piece: number;
  category: string;
  month?: number;
  year?: number;
  user: string;
}

interface PopUpConfirm {
  setPopUpConfirm: React.Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  title: string;
}

const PopUpConfirm: React.FC<PopUpConfirm> = ({
  setPopUpConfirm,
  onConfirm,
  title,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setPopUpConfirm(false);
    } else if (e.key === "Enter") {
      onConfirm();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown as any);
    return () => {
      window.removeEventListener("keydown", handleKeyDown as any);
    };
  }, []);

  return (
    <>
      <div className="PopUpConfirm">
        <span>{title}</span>
        <div className="btnContainer">
          <button onClick={() => setPopUpConfirm(false)}>Nie</button>
          <button onClick={() => onConfirm()}>Tak</button>
        </div>
      </div>
      <div className="Background" onClick={() => setPopUpConfirm(false)} />
    </>
  );
};

export default PopUpConfirm;
