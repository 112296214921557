import React, { SetStateAction, useContext, useState } from "react";
import toast from "react-hot-toast";
import "../style/Login.scss";

import { auth } from "../config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import ExpiredContext from "../context/Context";
import { useCookies } from "react-cookie";

interface loginProps {}

const Login: React.FC<loginProps> = () => {
  const [cookies, setCookie] = useCookies(["auth-token"]);

  const context = useContext(ExpiredContext);
  const { isAuth, setIsAuth } = context;

  const [loginEmail, setLoginEmail] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");

  const [registerEmail, setRegisterEmail] = useState<string>("");
  const [registerName, setRegisterName] = useState<string>("");
  const [registerPassword, setRegisterPassword] = useState<string>("");
  const [registerConfirmPassword, setRegisterConfirmPassword] =
    useState<string>("");

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loginEmail && loginPassword) {
      try {
        const result = await signInWithEmailAndPassword(
          auth,
          loginEmail,
          loginPassword
        );
        setIsAuth(true);
        setCookie("auth-token", result.user.refreshToken);

        toast.success("Udało się zalogować");
      } catch {
        toast.error("Nie udało się zalogować");
      }
    } else toast.error("Brak danych");
  };
  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (
      registerEmail &&
      registerPassword &&
      registerConfirmPassword &&
      registerName
    ) {
      if (registerPassword == registerConfirmPassword) {
        try {
          const result = await createUserWithEmailAndPassword(
            auth,
            registerEmail,
            registerPassword
          );
          const user = result.user;
          await updateProfile(user, { displayName: registerName });
          toast.success("Udało się zarejestrować");
        } catch (err) {
          toast.error("Nie udało się zarejestować");
          console.log(err);
        }
      } else toast.error("Hasła nie są takie same");
    } else toast.error("Brak danych");
  };

  return (
    <div className="LoginContainer">
      <form className="login" onSubmit={handleLogin}>
        <span className="title">Logowanie</span>
        <label>
          Email:
          <input
            type="email"
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
          />
        </label>
        <label>
          Hasło:
          <input
            type="password"
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
          />
        </label>
        <button type="submit">Zaloguj się</button>
      </form>

      <form className="register" onSubmit={handleRegister}>
        <span className="title">Rejestracja</span>
        <label>
          Email:
          <input
            type="email"
            value={registerEmail}
            onChange={(e) => setRegisterEmail(e.target.value)}
          />
        </label>
        <label>
          Nazwa:
          <input
            type="text"
            value={registerName}
            onChange={(e) => setRegisterName(e.target.value)}
          />
        </label>
        <label>
          Hasło:
          <input
            type="password"
            value={registerPassword}
            onChange={(e) => setRegisterPassword(e.target.value)}
          />
        </label>
        <label>
          Potwierdź hasło:
          <input
            type="password"
            value={registerConfirmPassword}
            onChange={(e) => setRegisterConfirmPassword(e.target.value)}
          />
        </label>
        <button type="submit">Zarejestruj się</button>
      </form>
    </div>
  );
};

export default Login;
