import React, {
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import "../style/PopUpSell.scss";

import { db } from "../config";
import {
  collection,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import ExpiredContext from "../context/Context";

interface IProductData {
  id: number;
  name: string;
  piece: number;
  category: string;
  month?: number;
  year?: number;
  user: string;
}

interface PopUpSellProps {
  id: string;
  name: string;
  pieces: number;
  setPopUp: React.Dispatch<SetStateAction<boolean>>;
}

const PopUpSell: React.FC<PopUpSellProps> = ({
  name,
  pieces,
  setPopUp,
  id,
}) => {
  const context = useContext(ExpiredContext);
  const { setSavedSoldArr, setSavedProductsArr } = context;

  const piecesRef = useRef<HTMLInputElement | null>(null);
  const priceRef = useRef<HTMLInputElement | null>(null);

  const [inputPieces, setInputPieces] = useState<number>(1);
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [price, setPrice] = useState<number | undefined>(undefined);

  useEffect(() => {
    piecesRef.current?.focus();
  }, []);
  useEffect(() => {
    priceRef.current?.focus();
  }, [checkbox]);

  const handleSold = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!checkbox && !price) {
      toast.error("Wprowadź cenę");
      return;
    }

    try {
      const productCollectionRef = collection(db, "product");
      const soldCollectionRef = collection(db, "sold");

      // Pobierz aktualne dane produktu
      const productDoc = await getDoc(doc(productCollectionRef, id));

      if (productDoc.exists()) {
        const productData = productDoc.data() as IProductData;

        if (inputPieces >= 1 && inputPieces <= productData.piece) {
          const updatedPieces = productData.piece - inputPieces;

          // Jeśli są jeszcze dostępne sztuki, zaktualizuj ilość w kolekcji "product"
          if (updatedPieces > 0) {
            await updateDoc(doc(productCollectionRef, id), {
              piece: updatedPieces,
            });
            setSavedSoldArr(null);
            setSavedProductsArr(null);
          } else {
            // Jeśli to była ostatnia sztuka, usuń produkt z kolekcji "product"
            await deleteDoc(doc(productCollectionRef, id));
            setSavedSoldArr(null);
            setSavedProductsArr(null);
          }

          // Pobierz aktualne dane sprzedaży
          const soldDoc = await getDoc(doc(soldCollectionRef, id));

          // Jeśli istnieje dokument w kolekcji "sold", dodaj nową ilość sprzedanych sztuk

          if (!checkbox) {
            // Jeśli nie istnieje dokument w kolekcji "sold", utwórz nowy dokument
            await addDoc(soldCollectionRef, {
              ...productData,
              piece: inputPieces,
              receiptIssued: checkbox, // Dodaj informację o paragonie
              price: price, // Dodaj informację o cenie (jeśli checkbox nie jest zaznaczony)
            });
            setSavedSoldArr(null);
            setSavedProductsArr(null);
          } else {
            if (soldDoc.exists()) {
              await updateDoc(doc(soldCollectionRef, id), {
                name: name,
                piece: increment(inputPieces),
                receiptIssued: checkbox, // Dodaj informację o paragonie
              });
            } else {
              // Jeśli nie istnieje dokument w kolekcji "sold", utwórz nowy dokument
              await setDoc(doc(soldCollectionRef, id), {
                ...productData,
                piece: inputPieces,
                receiptIssued: checkbox, // Dodaj informację o paragonie
              });
            }
            setSavedSoldArr(null);
            setSavedProductsArr(null);
          }

          toast.success("Produkt został sprzedany.");
        } else {
          toast.error(
            `Podana ilość jest nieprawidłowa, aktualna ilość sztuk to ${productData.piece}`
          );
        }
      } else {
        toast.error("Produkt nie istnieje w kolekcji.");
      }
    } catch (error) {
      console.error("Wystąpił błąd podczas sprzedaży produktu: ", error);
      toast.error("Coś poszło nie tak");
    }

    setPopUp(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setPopUp(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown as any);
    return () => {
      window.removeEventListener("keydown", handleKeyDown as any);
    };
  }, []);

  return (
    <>
      <form
        className={checkbox ? `PopUpSell` : "PopUpSell bigger"}
        onSubmit={handleSold}
      >
        <div className="topBar">{name}</div>
        <label>
          Liczba sztuk:
          <input
            ref={piecesRef}
            type="number"
            min={1}
            max={pieces}
            value={inputPieces}
            onChange={(e) => setInputPieces(parseInt(e.target.value))}
          />
        </label>
        <label>
          Paragon wystawiony?
          <input
            type="checkbox"
            checked={checkbox}
            onChange={() => setCheckbox((prev) => !prev)}
          />
        </label>
        {!checkbox && (
          <label className="price">
            <input
              ref={priceRef}
              type="number"
              min={1}
              step="0.01"
              placeholder="Cena sprzedaży poj. produktu"
              value={price || ""}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            />
          </label>
        )}
        <button type="submit">Oznacz jako sprzedane</button>
      </form>
      <div className="Background" onClick={() => setPopUp(false)} />
    </>
  );
};

export default PopUpSell;
