import React, { useContext, useEffect, useRef, useState } from "react";
import "../style/Add.scss";

import { auth, db } from "../config";

import { addDoc, collection } from "firebase/firestore";
import toast from "react-hot-toast";
import ExpiredContext from "../context/Context";
import PopUpConfirm from "./PopUpConfirm";

interface AddProps {}

const Add: React.FC<AddProps> = () => {
  const context = useContext(ExpiredContext);
  const { setSavedProductsArr } = context;

  type NamePrefixType =
    | ""
    | "DARK LINE"
    | "DARK LINE DOUBLE"
    | "MONO"
    | "DUO"
    | "DUO ICED"
    | "THE MASK"
    | "FLUO"
    | "LOONG"
    | "DOS BROS"
    | "SUMMER/SPRING TIME"
    | "PORN SERIES"
    | "RADLER"
    | "DOT VAPE"
    | "MAD VAPES"
    | "SOLO"
    | "PREMIX 5/15 DARK LINE"
    | "PREMIX 5/15 DARK LINE DOUBLE";
  type CategoryType = "" | "LONGFILL" | "PREMIX" | "LIQUID";

  interface IProductData {
    // id: number;
    name: string;
    piece: number;
    category: string;
    month?: number;
    year?: number;
    user: string;
    EAN?: number;
  }

  const EANRef = useRef<HTMLInputElement | null>(null);
  const namePrefixRef = useRef<HTMLSelectElement | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);
  const pieceRef = useRef<HTMLInputElement | null>(null);

  const [popUpConfirm, setPopUpConfirm] = useState<boolean>(false);

  const [EAN, setEAN] = useState<number | undefined>(undefined);
  const [namePrefix, setNamePrefix] = useState<NamePrefixType>("");
  const [name, setName] = useState<string>("");
  const [piece, setPiece] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>(undefined);
  const [year, setYear] = useState<number | undefined>(undefined);
  const [category, setCategory] = useState<CategoryType>("LONGFILL");

  useEffect(() => {
    nameRef.current?.focus();
  }, [namePrefix]);

  const handleAddProduct = async () => {
    if (name && piece) {
      const productCollectionRef = collection(db, "product");
      if (auth.currentUser) {
        try {
          const productData: IProductData = {
            // id: 1,
            name: `${namePrefix} ${name}`,
            piece,
            category,
            user: auth.currentUser.uid,
          };

          if (month !== undefined && year !== undefined) {
            productData.month = month;
            productData.year = year;
          }
          if (EAN !== undefined) productData.EAN = EAN;

          addDoc(productCollectionRef, productData);
          toast.success("Dodano produkt");
          EANRef.current?.focus();
          setName("");
          setPiece(undefined);
          // setCategory("LONGFILL");
          setMonth(undefined);
          setYear(undefined);
          setEAN(undefined);
          setSavedProductsArr(null);
          setPopUpConfirm(false);
        } catch (err) {
          toast.error("Coś poszło nie tak");
          console.log(err);
        }
      } else {
        toast.error("Coś poszło nie tak");
      }
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const handleOpenPopUpConfirm = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("enter");
    if (name && piece) {
      setPopUpConfirm(true);
    } else if (name) {
      pieceRef.current?.focus();
    } else if (EAN) {
      if (namePrefix) nameRef.current?.focus();
      else namePrefixRef.current?.focus();
    } else {
      EANRef.current?.focus();
    }
  };

  return (
    <div className="Add">
      <form onSubmit={(e) => handleOpenPopUpConfirm(e)}>
        <label>
          EAN:
          <input
            ref={EANRef}
            type="number"
            placeholder="EAN"
            value={EAN || ""}
            onChange={(e) => setEAN(parseInt(e.target.value))}
          />
        </label>
        <label>
          Prefix nazwy:
          <select
            ref={namePrefixRef}
            id="categorySelect"
            value={namePrefix}
            onChange={(e) => setNamePrefix(e.target.value as NamePrefixType)}
          >
            <option value=""></option>
            <option value="DARK LINE">DARK LINE</option>
            <option value="DARK LINE DOUBLE">DARK LINE DOUBLE</option>
            <option value="MONO">MONO</option>
            <option value="DUO">DUO</option>
            <option value="DUO ICED">DUO ICED</option>
            <option value="THE MASK">THE MASK</option>
            <option value="FLUO">FLUO</option>
            <option value="LOONG">LOONG</option>
            <option value="DOS BROS">DOS BROS</option>
            <option value="SUMMER/SPRING TIME">SUMMER/SPRING TIME</option>
            <option value="PORN SERIES">PORN SERIES</option>
            <option value="RADLER">RADLER</option>
            <option value="DOT VAPE">DOT VAPE</option>
            <option value="MAD VAPES">MAD VAPES</option>
            <option value="SOLO">SOLO</option>
            <option value="PREMIX 5/15 DARK LINE">PREMIX 5/15 DARK LINE</option>
            <option value="PREMIX 5/15 DARK LINE DOUBLE">
              PREMIX 5/15 DARK LINE DOUBLE
            </option>
          </select>
        </label>
        <label>
          *Nazwa:
          <input
            ref={nameRef}
            type="text"
            placeholder="Nazwa"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          *Sztuk:
          <input
            ref={pieceRef}
            type="number"
            value={piece || ""}
            min={1}
            onChange={(e) => setPiece(parseInt(e.target.value))}
          />
        </label>
        <label>
          Data wazności:
          <div className="date">
            <input
              type="number"
              min={1}
              max={12}
              placeholder="Miesiąc"
              value={month || ""}
              onChange={(e) => setMonth(parseInt(e.target.value))}
            />
            <input
              type="number"
              min={2018}
              max={currentYear}
              placeholder="Rok"
              value={year || ""}
              onChange={(e) => setYear(parseInt(e.target.value))}
            />
          </div>
        </label>

        <label>
          Kategoria:
          <select
            id="categorySelect"
            value={category}
            onChange={(e) => setCategory(e.target.value as CategoryType)}
          >
            <option value="LONGFILL">LONGFILL</option>
            <option value="PREMIX">PREMIX</option>
            <option value="LIQUID">LIQUID</option>
          </select>
        </label>
        <button type="submit">Dodaj</button>
      </form>
      {popUpConfirm && (
        <PopUpConfirm
          setPopUpConfirm={setPopUpConfirm}
          onConfirm={handleAddProduct}
          title={"Czy na pewno chcesz dodać ten paragon?"}
        />
      )}
    </div>
  );
};

export default Add;
