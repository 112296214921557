import React, { useContext, useEffect, useState } from "react";
import ExpiredContext from "../context/Context";
import "../style/App.scss";
import Add from "./Add";
import Login from "./Login";
import Nav from "./Nav";
import Sell from "./Sell";
import Sold from "./Sold";

function App() {
  const [toggle, setToggle] = useState<"SOLD" | "ADD" | "SELL">("SELL");

  const context = useContext(ExpiredContext);
  const { isAuth } = context;

  return (
    <div>
      {isAuth && (
        <>
          <Nav toggle={toggle} setToggle={setToggle} />
          {toggle == "SELL" && <Sell />}
          {toggle == "ADD" && <Add />}
          {toggle == "SOLD" && <Sold />}
        </>
      )}
      {!isAuth && <Login />}
    </div>
  );
}

export default App;
