import React, { useContext, useEffect, useState } from "react";
import "../style/Sell.scss";
import PopUpSell from "./PopUpSell";
import Product from "./Product";

import { auth, db } from "../config";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import ExpiredContext from "../context/Context";

interface SellProps {}

type CategoryType = "" | "LONGFILL" | "PREMIX" | "LIQUID";

interface IProductsArr {
  name: string;
  piece: number;
  month?: number;
  year?: number;
  category: CategoryType;
  user?: string;
  id: string;
  EAN?: number;
}
const Sell: React.FC<SellProps> = () => {
  const context = useContext(ExpiredContext);
  const { search, category, savedProductsArr, setSavedProductsArr } = context;
  const [productsArr, setProductsArr] = useState<IProductsArr[]>([]);

  const getData = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const collectionRef = collection(db, "product");
        const querySnapshot = await getDocs(
          query(collectionRef, where("user", "==", user.uid))
        );
        const productsData: IProductsArr[] = [];

        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            const productData = doc.data() as IProductsArr;

            const productWithId: IProductsArr = {
              ...productData,
              id: doc.id,
            };

            productsData.push(productWithId);
          } else {
            console.error("The document does not exist.");
          }
        });
        setSavedProductsArr(productsData);
        setProductsArr(productsData);
      } else {
        console.log("Brak użytkownika");
      }
    } catch (error) {
      console.error("Download data failed: ", error);
    }
  };

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (savedProductsArr === null) getData();
        else setProductsArr(savedProductsArr);
      } else {
        console.log("Brak użytkownika");
      }
    });

    const unsubscribeSnapshot = onSnapshot(collection(db, "product"), () => {
      if (auth.currentUser) {
        if (savedProductsArr === null) getData();
        else setProductsArr(savedProductsArr);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeSnapshot();
    };
  }, []);

  const [products, setProducts] = useState<any>([]);
  const getProducts = () => {
    const filteredProducts = productsArr.filter((item: IProductsArr) => {
      return (
        item.name.toLowerCase().includes(search.toLowerCase()) &&
        (category === "" ||
          item.category.toLowerCase().includes(category.toLowerCase()))
      );
    });

    const temporaryProducts = filteredProducts.map((item: IProductsArr) => {
      return (
        <Product
          key={item.id}
          name={item.name}
          pieces={item.piece}
          month={item.month}
          year={item.year}
          id={item.id}
          sold={false}
        />
      );
    });

    setProducts(temporaryProducts);
  };

  const getSearchProducts = () => {
    const filteredProducts = productsArr.filter((item: IProductsArr) => {
      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseCategory = item.category.toLowerCase();
      const lowerCaseEAN = item.EAN ? item.EAN.toString().toLowerCase() : "";

      return (
        (lowerCaseName.includes(search.toLowerCase()) &&
          (category === "" ||
            lowerCaseCategory.includes(category.toLowerCase()))) ||
        lowerCaseEAN.includes(search.toLowerCase())
      );
    });

    const temporaryProducts = filteredProducts.map((item: IProductsArr) => {
      return (
        <Product
          key={item.id}
          name={item.name}
          pieces={item.piece}
          month={item.month}
          year={item.year}
          id={item.id}
          sold={false}
        />
      );
    });

    setProducts(temporaryProducts);
  };

  useEffect(() => {
    if (search == "") getProducts();
    else getSearchProducts();
  }, [productsArr, search, category]);

  return (
    <div className="Sell">
      <span className="title">Sprzedaż: {productsArr.length}</span>
      {products}
    </div>
  );
};

export default Sell;
